import React from 'react';
interface Props{
    message?: string;
}
export default function LoadingComponent({message}:Props){
return (
    <div className="loader-wrapper dimmer">
      <div className="loader"></div>
      <div>
          {message && <p className="loader-message">{message}</p>}
      </div>
    </div>
  );
};