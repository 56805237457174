import React,{ useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useStore } from '../../stores/store';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import DialogBox from '../common/DialogBox';
import { AppEntity, NewAppEntity } from '../../models/appentity';
import { useEffect } from 'react';


interface IProps{
    app: AppEntity;
    showDialog: boolean;
    onSave: (app: NewAppEntity) => void;
    onCancel: () => void;
}

const AppsEditDialog : React.FC<IProps> = ({app,showDialog,onSave,onCancel}) => {
  const {appsStore:{isExists}} = useStore();
  const [initialValues, setInitialValues] = useState<NewAppEntity>({
    appId: 0,  
    appName: '',
    appDescription: '',
  });
  useEffect(()=>{
    if(app)
        setInitialValues({
    appId: app.appId,  
    appName: app.appName,
    appDescription: app.appDescription,
  });
  },[app])
  const validationSchema = Yup.object({
      appName: Yup.string().required('Kindly provide a name').min(3,'Name must be alteast 3 characters').max(50, 'Maximum 50 characters allowed'),
      appDescription: Yup.string().required('Kindly provide a description').min(5,'Name must be alteast 5 characters').max(100, 'Maximum 100 characters allowed'),
  });


  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={onSave}>
      {({handleSubmit,isSubmitting}) => (
        <Form onSubmit={handleSubmit}>
          <DialogBox title='Add New Application' showDialog={showDialog} onOK={handleSubmit} onCancel={onCancel} okDisabled={isSubmitting} isSubmitting={isSubmitting}>
                <div className="form-input-row">
                    <label htmlFor='appName' className="form-input-row-label">Name</label>
                    <Field name='appName'className='form-input-row-field' type='text' />
                    <ErrorMessage name='appName' component='span' className='form-input-row-error' />
                </div>
                <div className="form-input-row">
                    <label htmlFor='appDescription' className="form-input-row-label">Description</label>
                    <Field name='appDescription'className='form-input-row-field' type='text' />
                    <ErrorMessage name='appDescription' component='span' className='form-input-row-error' />
                </div>
          </DialogBox>
        </Form>
      )}
    </Formik>
  )
}

export default observer(AppsEditDialog);