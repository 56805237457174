import React from 'react'
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { LoadingComponent, LoginBox } from "./components/common";
import {Devices} from "./components/devices";
import { Header } from "./components/layout";
import './css/styles.css';
import { useStore } from "./stores/store";
import Apps from './components/apps/Apps';

function App() {
  const {commonStore:{token, user,appLoading,setAppLoaded,getUser},devicesStore:{loadDevices},appsStore:{loadApps}} = useStore()
  useEffect(() =>{
    loadApps();
    loadDevices();
  })
  useEffect(()=>{
    if(token && !user)
      getUser().then(() => setAppLoaded());
    else
      setAppLoaded();
  },[token,user,getUser,setAppLoaded])
  if(appLoading)
    return <LoadingComponent message="Loading app.." />

  return (
    <>
      <Header />
      <div className='container'>
        {user ? (
          <div className='main-contents'>
              <Apps />
              <Devices />
          </div>
        ) : (
          <div className='login-container'>
            <LoginBox />
          </div>
        )}
      </div>
      <div className='footer'>
        <div className='footer-container'>
          <span>Copyright 2022, Axcess IT</span>
        </div>
      </div>
    </>
  );
}

export default observer(App);
