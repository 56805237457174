import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { ApiResponseStatusEnum } from "../models/apiresponse";
import { AppEntity, NewAppEntity } from './../models/appentity';
import { store } from "./store";


export default class AppsStore{

    apps: AppEntity[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    loadApps = () => {
        store.commonStore.setApiCalled();
        agent.Apps.all()
                .then((response)=>{
                if(response.status === ApiResponseStatusEnum.ResponseStatusOk)
                    runInAction(() => this.apps = response.result);
                else
                    store.commonStore.setLastError(response.error.description);
                });
            store.commonStore.setApiCalled(false);
    }

    addNew = async (app: NewAppEntity) => {
        store.commonStore.setApiCalled();
        agent.Apps.new(app) 
                .then((response) => {
                    if(response.status === ApiResponseStatusEnum.ResponseStatusOk)
                        runInAction(() => this.apps = [...this.apps,response.result])
                    else
                        store.commonStore.setLastError(response.error.description);
                });
        store.commonStore.setApiCalled(false);
    }
    update = (app: NewAppEntity) => {
        store.commonStore.setApiCalled();
        agent.Apps.update(app) 
                .then((response) => {
                    if(response.status === ApiResponseStatusEnum.ResponseStatusOk)
                        runInAction(() => this.apps = [...this.apps.filter(a => a.appId !== app.appId),response.result])
                    else 
                        store.commonStore.setLastError(response.error.description);
                });
        store.commonStore.setApiCalled(false);
    }
    activate = (id: number) => {
    store.commonStore.setApiCalled();
    let app = this.apps.find(a => a.appId === id && !a.isActive);
    if(app)
    {
        agent.Apps.activate(id) 
                  .then((response) => {
                    if(response.status === ApiResponseStatusEnum.ResponseStatusOk)
                    {
                        app!.isActive = true;
                        runInAction(() => this.apps = [...this.apps.filter(a => a.appId !== id),app!])
                    }
                    else
                        store.commonStore.setLastError(response.error.description);
                  });
       
    }
    store.commonStore.setApiCalled(false);
  }

   deactivate = (id: number) => {
    store.commonStore.setApiCalled();
    let app = this.apps.find(a => a.appId === id && a.isActive);
    if(app)
    {
        agent.Apps.deactivate(id) 
                  .then((response) => {
                    if(response.status === ApiResponseStatusEnum.ResponseStatusOk)
                    {
                        app!.isActive = false;
                        runInAction(() => this.apps = [...this.apps.filter(a => a.appId !== id),app!])
                    }
                    else
                        store.commonStore.setLastError(response.error.description);
                  });
       
    }
    store.commonStore.setApiCalled(false);
  }

  isExists =  (id: number) => {
    let app = this.apps.find(a => a.appId === id)
    if(app)
        return true;
    return false;
  }
}