export interface ApiResponse {
    status: ApiResponseStatusEnum;
    error: ApiResponseError;
}

export interface ApiResponseT<T> extends ApiResponse {
    result: T;
}

export interface ApiResponseError {
    errorCode: ApiResponseErrorEnum;
    description: string;
}

export enum ApiResponseStatusEnum {
    ResponseStatusError,
    ResponseStatusOk
}

export enum ApiResponseErrorEnum {
    ResponseErrorNone,
    ResponseErrorAuthenticationFailed,
    ResponseErrorUnAuthorized,
    ResponseErrorNotFound,
    ResponseErrorRequestFailed,
    ResponseErrorServerError,
    ResponseErrorBadRequest,
    ResponseErrorInvalidLicense
}