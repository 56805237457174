import React,{ useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useStore } from './../../stores/store';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import DialogBox from '../common/DialogBox';
import { NewAppEntity } from './../../models/appentity';


interface IProps{
    showDialog: boolean;
    onSave: (app: NewAppEntity) => void;
    onCancel: () => void;
}

const AppsAddDialog : React.FC<IProps> = ({showDialog,onSave,onCancel}) => {
  const {appsStore:{isExists}} = useStore();
  const [initialValues, setInitialValues] = useState<NewAppEntity>({
    appId: 0,  
    appName: '',
    appDescription: '',
  });
  const validationSchema = Yup.object({
      appId: Yup.number().required('Kindly provide app id').test('id-exists','App Id already exists!',(value, context) => value ? !isExists(value) : false),
      appName: Yup.string().required('Kindly provide a name').min(3,'Name must be alteast 3 characters').max(50, 'Maximum 50 characters allowed'),
      appDescription: Yup.string().required('Kindly provide a description').min(5,'Name must be alteast 5 characters').max(100, 'Maximum 100 characters allowed'),
  });


  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={onSave}>
      {({handleSubmit,isSubmitting}) => (
        <Form onSubmit={handleSubmit}>
          <DialogBox title='Add New Application' showDialog={showDialog} onOK={handleSubmit} onCancel={onCancel} okDisabled={isSubmitting} isSubmitting={isSubmitting}>
                <div className='form-input-row'>
                    <label htmlFor='appId' className="form-input-row-label">App Id</label>
                    <Field name='appId'className='form-input-row-field' type='number' />
                    <ErrorMessage name='appId' component='span' className='form-input-row-error' />
                </div>
                <div className="form-input-row">
                    <label htmlFor='appName' className="form-input-row-label">Name</label>
                    <Field name='appName'className='form-input-row-field' type='text' />
                    <ErrorMessage name='appName' component='span' className='form-input-row-error' />
                </div>
                <div className="form-input-row">
                    <label htmlFor='appDescription' className="form-input-row-label">Description</label>
                    <Field name='appDescription'className='form-input-row-field' type='text' />
                    <ErrorMessage name='appDescription' component='span' className='form-input-row-error' />
                </div>
          </DialogBox>
        </Form>
      )}
    </Formik>
  )
}

export default observer(AppsAddDialog);