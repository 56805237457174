import React, { useState } from 'react';
import { useStore } from './../../stores/store';
import { observer } from 'mobx-react-lite';
import { AiOutlineClose, AiOutlineCheck } from "react-icons/ai";
import { AppEntity, NewAppEntity } from './../../models/appentity';
import AppsAddDialog from './AppsAddDialog';
import AppsEditDialog from './AppsEditDialog';
import { MdEdit } from 'react-icons/md';

const Devices = () => {
  const {appsStore:{apps,addNew,update,activate,deactivate}} = useStore();
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [currentApp, setCurrentApp] = useState<AppEntity|undefined>();

  const handleNew = () => {
    setCurrentApp(undefined);
    setShowAddDialog(true);
  }
  
  const handleActivateToggle = (id: number, toggle: boolean) => {
    let app = apps.find(a => a.appId === id);
    if(app)
      if(toggle)
        activate(id);
      else
        deactivate(id);
  }

  const handleEdit = (id: number) => {
    let app = apps.find(a => a.appId === id);
    if(app){
        setCurrentApp(app);        
        setShowEditDialog(true);
    }
  }
  
  const handleSave = (app: NewAppEntity) => {
    if(currentApp)
      update(app);
    else
      addNew(app);
    setShowAddDialog(false);
    setShowEditDialog(false);
    setCurrentApp(undefined);
  }
  const handleCancel = () => {
    setCurrentApp(undefined);
    setShowAddDialog(false);
    setShowEditDialog(false);
  }
  return (
    <div className="data-list-container">
        <div className="data-list-header">
            <h2 className="data-list-title">Applications</h2>
            <button type="button" className="btn btn-tiny" onClick={()=>handleNew()}>Add</button>
        </div>
        <ul>
        {apps.length === 0 ? (
            <span>No Applications</span>
        ) : (
            <>
                {apps.map((a,i) => (
                    <li key={`${a.appId}_${i}`}>
                      <span className={a.isActive ? '' : 'inactive'}>{a.appId} - {a.appName}</span>
                      <MdEdit onClick={() => handleEdit(a.appId)} />
                    {a.isActive ? (
                      <AiOutlineClose className="icon-status-inactive" onClick={()=>handleActivateToggle(a.appId,false)}/>
                    ) : (
                      <AiOutlineCheck className="icon-status-active" onClick={()=>handleActivateToggle(a.appId,true)}/>
                    )}
                    </li>
                ))}
            </>
        )}
        </ul>
        {showAddDialog && <AppsAddDialog showDialog={showAddDialog} onSave={handleSave} onCancel={handleCancel} />}
        {showEditDialog && currentApp && <AppsEditDialog app={currentApp} showDialog={showAddDialog} onSave={handleSave} onCancel={handleCancel} />}
    </div>
  )
}

export default observer(Devices);