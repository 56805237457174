import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { ApiResponseStatusEnum } from '../models/apiresponse';
import { DeviceEntity, DeviceListEntity, NewDeviceEntity } from './../models/device';
import { store } from './store';


export default class DevicesStore {
    
    devices: DeviceListEntity[] = [];
    currentDevice: DeviceEntity | undefined;
    sortColumn = 3;
    sortMode = true;
    constructor() {
        makeAutoObservable(this);
    }

    setSort = (col: number, mode: boolean = true) => {
        runInAction(() => {
            this.sortColumn = col;
            this.sortMode = mode;
        })
        this.sortDevices();
    }

    loadDevices = () =>{
        store.commonStore.setApiCalled();
        agent.Devices.all()
                .then((response)=>{
                if(response.status === ApiResponseStatusEnum.ResponseStatusOk){
                    runInAction(() => this.devices = response.result);
                    this.sortDevices();
                }
                else
                    store.commonStore.setLastError(response.error.description);
                store.commonStore.setApiCalled(false);
                });
            }

    sortDevices = () => {
        switch(this.sortColumn)
        {
            case 1:
                if(this.sortMode)
                    runInAction(() => this.devices = this.devices.sort((a,b) => (a.name > b.name) ? 1 : -1 ))
                else
                    runInAction(() => this.devices = this.devices.sort((a,b) => (a.name > b.name) ? -1 : 1 ))
                break;                
            case 2:
                if(this.sortMode)
                    runInAction(() => this.devices = this.devices.sort((a,b) => (a.group > b.group) ? 1 : -1 ))
                else
                    runInAction(() => this.devices = this.devices.sort((a,b) => (a.group > b.group) ? -1 : 1 ))
                break;                
            break;                
            case 3: 
                if(this.sortMode)
                    runInAction(() => this.devices = this.devices.sort((a,b) => (a.appId > b.appId) ? 1 : -1 ))
                else
                    runInAction(() => this.devices = this.devices.sort((a,b) => (a.appId > b.appId) ? -1 : 1 ))
                break;                
            case 4:
                if(this.sortMode)
                    runInAction(() => this.devices = this.devices.sort((a,b) => (a.lastOnline > b.lastOnline) ? 1 : -1 ))
                else
                    runInAction(() => this.devices = this.devices.sort((a,b) => (a.lastOnline > b.lastOnline) ? -1 : 1 ))
                break;   
            case 5:
                if(this.sortMode)
                    runInAction(() => this.devices = this.devices.sort((a,b) => (a.lastOffline > b.lastOffline) ? 1 : -1 ))
                else
                    runInAction(() => this.devices = this.devices.sort((a,b) => (a.lastOffline > b.lastOffline) ? -1 : 1 ))
                break;   
            case 6:
                if(this.sortMode)
                    runInAction(() => this.devices = this.devices.sort((a,b) => (a.status > b.status) ? 1 : -1 ))
                else
                    runInAction(() => this.devices = this.devices.sort((a,b) => (a.status > b.status) ? -1 : 1 ))
                break;   
            case 7:
                if(this.sortMode)
                    runInAction(() => this.devices = this.devices.sort((a,b) => (a.lastUpdated > b.lastUpdated) ? 1 : -1 ))
                else
                    runInAction(() => this.devices = this.devices.sort((a,b) => (a.lastUpdated > b.lastUpdated) ? -1 : 1 ))
                break;  
        }
    }
    getDevice = (id: number) => {
    store.commonStore.setApiCalled();
    this.currentDevice = undefined;
    agent.Devices.get(id)
            .then((response)=>{
              if(response.status === ApiResponseStatusEnum.ResponseStatusOk)
                runInAction(()=>this.currentDevice = response.result);
              else
                store.commonStore.setLastError(response.error.description);
            });
        store.commonStore.setApiCalled(false);
    }
    unsetCurrentDevice = () => {
        this.currentDevice = undefined;
    }

    addNew = async (device: NewDeviceEntity) => {
        store.commonStore.setApiCalled();
        agent.Devices.new(device) 
                .then((response) => {
                    if(response.status === ApiResponseStatusEnum.ResponseStatusOk){
                        runInAction(() => this.devices = [...this.devices,response.result])
                        this.sortDevices();
                    }
                    else 
                        store.commonStore.setLastError(response.error.description);
                    store.commonStore.setApiCalled(false);
                });
    }
    update = (id: number, device: NewDeviceEntity) => {
        store.commonStore.setApiCalled();
        agent.Devices.update(id,device) 
                .then((response) => {
                    if(response.status === ApiResponseStatusEnum.ResponseStatusOk){
                        runInAction(() => this.devices = [...this.devices.filter(d => d.primaryId !== id),response.result])
                        this.sortDevices();
                    }
                    else 
                        store.commonStore.setLastError(response.error.description);
                    store.commonStore.setApiCalled(false);
                });
    }
    setnotify = (id: number, notify: boolean) => {
        store.commonStore.setApiCalled();
        agent.Devices.setnotify(id,notify)
                .then((response) => {
                    if(response.status === ApiResponseStatusEnum.ResponseStatusOk){
                        runInAction(() => this.devices = [...this.devices.filter(d => d.primaryId !== id),response.result])
                        this.sortDevices();
                    }
                    else
                        store.commonStore.setLastError(response.error.description);
                    store.commonStore.setApiCalled(false);
                })
    }

    remove = (id: number) => {
    store.commonStore.setApiCalled();
    agent.Devices.delete(id) 
              .then((response) => {
                if(response.status === ApiResponseStatusEnum.ResponseStatusOk){
                    runInAction(() => this.devices = [...this.devices.filter(d => d.primaryId !== id)])
                    this.sortDevices();
                }
                else
                  store.commonStore.setLastError(response.error.description);
                store.commonStore.setApiCalled(false);
              });
  }
  isAppExists =  (id: string | undefined, appId: number) => {
    if(id){
        let device = this.devices.find(d => d.id === id && d.appId === appId)
        return device ? true : false;
    }
    else
        return false
  }
}