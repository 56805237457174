import React from 'react'
import { observer } from "mobx-react-lite";
import { Navbar } from ".";
import { useStore } from "../../stores/store";
import { GiWifiRouter } from "react-icons/gi"

const Header = () => {
  const {commonStore:{isLoggedIn}} = useStore();
  return (
    <div className='header'>
        <div className='navbar-brand'>
            <GiWifiRouter className='navbar-logo' />
            <h1 className="logo-title">Axcess IOT Manager</h1>
        </div>
        {isLoggedIn && <Navbar />}
      </div>
  )
}

export default observer(Header);