import React from 'react'
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";

const Navbar = () => {
  const {commonStore:{user,logoff}} = useStore();
  const handleLogOff = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    logoff();
  }
  return (
    <>
      <input type="checkbox" id="navbar-toggler" className='navbar-toggler' />
      <nav>
          <ul className="navbar-menu-main">
            {user && (
              <li className="nav-dropdown">
                  <a href="#">{user.username}</a>
                  <div className="navbar-menu-sub">
                      <ul>
                          <li><a href="#" onClick={handleLogOff} >Log Off</a></li>
                      </ul>
                  </div>
              </li>
            )}
          </ul>
        </nav>
          <label htmlFor='navbar-toggler' className="navbar-hamburger">
            <span></span>
        </label>
    </>
  )
}

export default observer(Navbar);