import React from 'react';
interface IProps{
    title: string;
    message: string;
    showAlert: boolean;
    onOK: () => void;
    onCancel?: () => void;
    okLabel?: string;
    cancelLabel?: string;
    showCancel?:boolean;
}

const AlertBox : React.FC<IProps> = ({
    title,
    message,
    showAlert,
    onOK,
    onCancel,
    okLabel = 'Yes',
    cancelLabel = 'No',
    showCancel = false
}) => {
  return (
    <div className={`dialog-wrapper ${showAlert ? "dialog-show" : ""}`}>
        <div className='alert-box'>
            <div className="alert-box-title">
                <h2>{title}</h2>
            </div>
            <div className='alert-box-body'>
                <span>{message}</span>
            </div>
            <div className="alert-box-footer">
                {showCancel && <button className="btn btn-with-icon btn-cancel" onClick={onCancel}>{cancelLabel}</button>}
                <button type='button' className="btn btn-with-icon btn-default" onClick={onOK}>{okLabel}</button>
            </div>
        </div>
    </div>
  )
}

export default AlertBox