import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import DevicesStore from "./deviceStore";
import AppsStore from './appsStore';

interface Store {
    commonStore: CommonStore;
    devicesStore: DevicesStore;
    appsStore: AppsStore;
}

export const store: Store = {
    commonStore: new CommonStore(),
    devicesStore: new DevicesStore(),
    appsStore: new AppsStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}