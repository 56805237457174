import React,{ useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { NewDeviceEntity } from './../../models/device';
import { useStore } from './../../stores/store';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import DialogBox from '../common/DialogBox';
import { useEffect } from 'react';
import {v4} from 'uuid'

interface IProps{
    showDialog: boolean;
    onSave: (device: NewDeviceEntity) => void;
    onCancel: () => void;
    isDuplicate: boolean;
    reuseUID: string | undefined;
    parentDevice: string | undefined;
}

const DeviceDialog : React.FC<IProps> = ({showDialog,onSave,onCancel, isDuplicate, reuseUID, parentDevice}) => {
  const {devicesStore:{currentDevice,isAppExists},appsStore:{apps}} = useStore();
  const [initialValues, setInitialValues] = useState<NewDeviceEntity>({
      id: reuseUID ? reuseUID : v4(),
      name: '',
      description: '',
      group: '',
      alertInterval:  10,
      alertEmailIds: '',
      offlineMessage: '',
      onlineMessage: '',
      appId: 0,
      sendOfflineNotification: true,
  });
  useEffect(() => {
    if(currentDevice){
      setInitialValues({
      id: v4(),
      name: currentDevice.name,
      description: currentDevice.description,
      group: currentDevice.group,
      alertInterval:  currentDevice.alertInterval,
      alertEmailIds: currentDevice.alertEmailIds,
      offlineMessage: currentDevice.offlineMessage,
      onlineMessage: currentDevice.onlineMessage,
      appId: currentDevice.appId,
      sendOfflineNotification: currentDevice.sendOfflineNotification
      })
    }
  },[currentDevice])
  const validationSchema = {
      name: Yup.string().required('Kindly provide a name').min(3,'Name must be alteast 3 characters').max(50, 'Maximum 50 characters allowed'),
      description: Yup.string().required('Kindly provide a description').min(5,'Name must be alteast 5 characters').max(100, 'Maximum 100 characters allowed'),
      group: Yup.string().required('Kindly provide the group name').min(3,'Name must be alteast 3 characters').max(50, 'Maximum 50 characters allowed'),
      alertInterval: Yup.number().min(1,'Interval must be atleast 1'),
      alertEmailIds: Yup.string().required('Kindly provide coma seperated email id(s) for sending alerts').min(11, 'Must be atleast 11 characters'),
      offlineMessage: Yup.string().required('Kindly provide the offline message template').min(10, 'Must be atleast 10 characters').max(2000, 'Maximum 2000 characters'),
      onlineMessage: Yup.string().required('Kindly provide the online message template').min(10, 'Must be atleast 10 characters').max(2000, 'Maximum 2000 characters'),
      appId: Yup.number().required('Kindly provide app id').test('id-exists','App Id already exists!',(value, context) => value ? !isAppExists(reuseUID,value) : false)
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object(validationSchema)}
      enableReinitialize={true}
      onSubmit={onSave}>
      {({handleSubmit,isSubmitting,setFieldValue,handleBlur,values}) => (
        <Form onSubmit={handleSubmit}>
          <DialogBox title={(currentDevice && !isDuplicate) ? `Edit ${currentDevice.name}` : (parentDevice ? `Add App to ${parentDevice}` : 'Add New Device')} showDialog={showDialog} onOK={handleSubmit} onCancel={onCancel} okDisabled={isSubmitting} isSubmitting={isSubmitting}>
              <div className="form-input-row">
                <label htmlFor='name' className="form-input-row-label">Name</label>
                <Field name='name'className='form-input-row-field' type='text' />
                <ErrorMessage name='name' component='span' className='form-input-row-error' />
              </div>
              <div className="form-input-row">
                <label htmlFor='description' className="form-input-row-label">Description</label>
                <Field name='description'className='form-input-row-field' type='text' />
                <ErrorMessage name='description' component='span' className='form-input-row-error' />
              </div>
              <div className="form-input-row">
                <label htmlFor='group' className="form-input-row-label">Group Name</label>
                <Field name='group'className='form-input-row-field' type='text' />
                <ErrorMessage name='group' component='span' className='form-input-row-error' />
              </div>
                <div className="form-input-row">
                    <label htmlFor='appId' className="form-input-row-label">Application</label>
                    <select name='appId' value={values.appId} className='form-input-row-field' onBlur={handleBlur} onChange={(event) => setFieldValue('appId',parseInt(event.target.value))}>
                        <option key={0} value={0}>Select Application</option>
                        {apps.filter(a => a.isActive).map((a,i) => (
                            <option key={`${a.appId}_${i}`} value={a.appId}>{a.appId} - {a.appName}</option>
                        ))}
                    </select>
                    <ErrorMessage name='appId' component='span' className='form-input-row-error' />
                </div>
                <div className='form-input-row'>
                  <label htmlFor='alertInterval' className="form-input-row-label">Alert Interval</label>
                  <Field name='alertInterval'className='form-input-row-field' type='number' />
                  <ErrorMessage name='alertInterval' component='span' className='form-input-row-error' />
                </div>
              <div className="form-input-row">
                <label htmlFor='alertEmailIds' className="form-input-row-label">Alert Email Id(s)</label>
                <Field name='alertEmailIds'className='form-input-row-field' type='text' />
                <ErrorMessage name='alertEmailIds' component='span' className='form-input-row-error' />
              </div>
              <div className="form-input-row">
                <label htmlFor='offlineMessage' className="form-input-row-label">Offline Message</label>
                <Field name='offlineMessage'className='form-input-row-field' type='text' />
                <ErrorMessage name='offlineMessage' component='span' className='form-input-row-error' />
              </div>
              <div className="form-input-row">
                <label htmlFor='onlineMessage' className="form-input-row-label">Online Message</label>
                <Field name='onlineMessage'className='form-input-row-field' type='text' />
                <ErrorMessage name='onlineMessage' component='span' className='form-input-row-error' />
              </div>
              <div className="form-input-row">
                <label htmlFor='sendOfflineNotification' className="form-input-row-label">Send Notification Reminders</label>
                <Field name='sendOfflineNotification'className='form-input-row-check' type='checkbox' />
                <ErrorMessage name='sendOfflineNotification' component='span' className='form-input-row-error' />
              </div>

           </DialogBox>
        </Form>
      )}
    </Formik>
  )
}

export default observer(DeviceDialog);