import React, { useState } from 'react';
import {BiPlus} from 'react-icons/bi'
import DeviceGrid from './DeviceGrid'
import { useStore } from './../../stores/store';
import DeviceDialog from './DeviceDialog';
import { AlertBox } from '../common';
import { NewDeviceEntity } from './../../models/device';
import { observer } from 'mobx-react-lite';
import { exists } from '../../utils/utils';

const Devices = () => {
  const {devicesStore:{devices,currentDevice,unsetCurrentDevice,addNew,getDevice,update,setnotify,remove}} = useStore();
  const [showDialog, setShowDialog] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isDuplicateDevice, setIsDuplicateDevice] = useState(false);
  const [reuseUID, setReuseUID] = useState<string|undefined>();
  const [parentDeviceName, setParentDeviceName] = useState<string|undefined>();

  const handleNew = () => {
    unsetCurrentDevice();
    setIsDuplicateDevice(false);
    setShowDialog(true);
  }
  const handleConfirmDelete = (id: number) => {
    if(devices.find(d => d.primaryId === id)){
      getDevice(id);
      setShowAlert(true);
    }
  }
  const handleDelete = () => {
    if(currentDevice)
      remove(currentDevice.primaryId);
    unsetCurrentDevice();
    setShowAlert(false);
  }
  const handleNotify = (id: number, notify: boolean) => {
    if(exists(devices,id)){
      setnotify(id,notify);
    }
  }
  const handleEdit = (id: number) => {
    if(devices.find(d => d.primaryId === id)){
      getDevice(id);
      setIsDuplicateDevice(false);
      setShowDialog(true);
    }
  }
  const handleDuplicate = (id: number) => {
    if(devices.find(d => d.primaryId === id)){
      getDevice(id);
      setIsDuplicateDevice(true);
      setShowDialog(true);
    }
  }
  const handleAddApp = (id: number) => {
    let device = devices.find(d => d.primaryId === id);
    if(device){
      setReuseUID(device.id);
      setParentDeviceName(device.name);
      setShowDialog(true);
    }
  }

  const handleSave = (device: NewDeviceEntity) => {
    if(currentDevice && !isDuplicateDevice)
      update(currentDevice.primaryId,device);
    else
      addNew(device);
    setShowDialog(false);
    unsetCurrentDevice();
    setIsDuplicateDevice(false);
    setReuseUID(undefined);
    setParentDeviceName(undefined);
  }
  const handleCancel = () => {
    unsetCurrentDevice();
    setIsDuplicateDevice(false);
    setShowDialog(false);
    setShowAlert(false);
    setReuseUID(undefined);
    setParentDeviceName(undefined);
  }
  return (
    <>
        <div className='data-grid-header'>
            <h2>Devices</h2>
            <button className='btn btn-with-icon btn-default' onClick={() => handleNew()} ><BiPlus />New</button>
        </div>
        <DeviceGrid handleNotify={handleNotify} handleEdit={handleEdit} handleAddApp={handleAddApp} handleDuplicate={handleDuplicate}  handleDelete={handleConfirmDelete} />
        {showDialog && <DeviceDialog showDialog={showDialog} onCancel={handleCancel} onSave={handleSave} isDuplicate={isDuplicateDevice} reuseUID={reuseUID} parentDevice={parentDeviceName} />}
        {showAlert && currentDevice && <AlertBox title='Confirm Delete' message={`Are you sure you want to delete ${currentDevice.name} ?`} onOK={handleDelete} showAlert={showAlert} onCancel={handleCancel} showCancel={true} />}
    </>
  )
}

export default observer(Devices);