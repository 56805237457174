import axios, { AxiosResponse } from "axios";
import { ApiResponse, ApiResponseT } from "../models/apiresponse";
import { LoggedUser, LoginDto } from './../models/user';
import { DeviceEntity, DeviceListEntity, NewDeviceEntity } from './../models/device';
import { AppEntity, NewAppEntity } from "../models/appentity";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem("alm_jwt");
    if (token) {
        if (config.headers) 
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const responseBody = (response: AxiosResponse) => response.data;

const request = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) =>  axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody)
};

const User = {
  login: (user: LoginDto) : Promise<ApiResponseT<LoggedUser>> => request.post("/user/login",user),
  currentUser: () : Promise<ApiResponseT<LoggedUser>> => request.get("/user/current"),
};

const Devices = {
 all: () : Promise<ApiResponseT<DeviceListEntity[]>> => request.get('/devices'),
 get: (id: number) : Promise<ApiResponseT<DeviceEntity>> => request.get(`/devices/${id}`),
 new: (device: NewDeviceEntity) : Promise<ApiResponseT<DeviceListEntity>> => request.post('/devices/new',device),
 update: (id: number,device: NewDeviceEntity) : Promise<ApiResponseT<DeviceListEntity>> => request.post(`/devices/update/${id}`,device),
 setnotify: (id: number, notify: boolean) : Promise<ApiResponseT<DeviceListEntity>> => request.post(`/devices/notify/${id}/${notify}`,{}),
 delete: (id: number) : Promise<ApiResponse> => request.del(`/devices/delete/${id}`),
}

const Apps = {
all: () : Promise<ApiResponseT<AppEntity[]>> => request.get('/apps'),
new: (app: NewAppEntity) : Promise<ApiResponseT<AppEntity>> => request.post('/apps/new',app),
update: (app: NewAppEntity) : Promise<ApiResponseT<AppEntity>> => request.post('/apps/update/',app),
activate: (id: number) : Promise<ApiResponse> => request.del(`/apps/activate/${id}`),
deactivate: (id: number) : Promise<ApiResponse> => request.del(`/apps/deactivate/${id}`),
}  

export default {User, Devices, Apps}