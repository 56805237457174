import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { ApiResponseStatusEnum } from "../models/apiresponse";
import { LoggedUser, LoginDto } from "../models/user";

export default class CommonStore {
    appLoading = true;
    apiCalled = false;
    apiReturned = false;
    apiError = false;
    lastError = "";

    token: string | null = window.localStorage.getItem("alm_jwt");
    user: LoggedUser | null = null; 

    constructor() {
            makeAutoObservable(this);
            reaction(
                () => this.token,
                (token) => {
                    if (token) {
                    window.localStorage.setItem("alm_jwt", token);
                    } else {
                    window.localStorage.removeItem("alm_jwt");
                    }
                }
            );
    }

    get isLoggedIn() {
            return !!this.user;
    }

    setToken = (token: string | null) => {
        this.token = token;
    };

    setAppLoaded = () => {
        this.appLoading = false;
    }

    resetLastError = () => {
        this.lastError = "";
    }
    
    setApiCalled = (called: boolean = true) => {
        this.apiCalled = called;
        runInAction(() => {
            this.apiReturned = !called;
            if(called)
                this.resetLastError();
        });
    }
    
    setLastError = (error: string) => {
        this.apiReturned = true;
        runInAction(() => {
            this.apiError = true;
            this.lastError = error;
        })
    }

    login =async (login: LoginDto) => {
    this.setApiCalled();
    agent.User.login(login)
                .then((response) => {
                    if(response.status === ApiResponseStatusEnum.ResponseStatusOk){
                        runInAction(() => {
                            this.user = response.result;
                            this.token = response.result.token;
                        })
                    } 
                    else
                        throw response.error.description;
                    this.setApiCalled(false);
                });
  }

  logoff = () => {
    runInAction(()=>{
        this.user = null;
        this.token = null;
    })
  }
  
  getUser = async () => {
    this.setApiCalled();
    agent.User.currentUser()
                .then((response) => {
                    if(response.status === ApiResponseStatusEnum.ResponseStatusOk){
                        runInAction(() => {
                            this.user = response.result;
                            this.token = response.result.token;
                        })
                    }
                })    
    this.setApiCalled(false);
  }

}