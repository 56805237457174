import { format } from 'date-fns';
import React from 'react'
import { BiDuplicate } from 'react-icons/bi';
import { BsClipboardCheck } from 'react-icons/bs';
import {MdEdit,MdDelete} from 'react-icons/md'
import { truncate } from '../../utils/utils';
import { DeviceListEntity } from './../../models/device';
import { AiOutlineAppstoreAdd } from 'react-icons/ai';
import {IoIosNotifications,IoMdNotificationsOff} from 'react-icons/io'

interface IProps{
  device: DeviceListEntity;
  handleNotify: (id: number,notify: boolean) => void;
  handleEdit: (id: number) => void;
  handleAddApp: (id: number) => void;
  handleDuplicate: (id: number) => void;
  handleDelete: (id: number) => void;
}

const DeviceGridRow : React.FC<IProps> = ({device, handleNotify,handleEdit, handleAddApp, handleDuplicate, handleDelete}) => {
  const handleCopyId = (id: string) => {
    navigator.clipboard.writeText(id);
  }
  return (
    <div className='data-grid-row'>
        <div key={`${device.primaryId}_1`} className='data-grid-col'>{truncate(device.name,50)}</div>
        <div key={`${device.primaryId}_2`} className='data-grid-col'>{device.group}</div>
        <div key={`${device.primaryId}_3`} className='data-grid-col'>{device.appId}</div>
        <div key={`${device.primaryId}_4`} className='data-grid-col'>{format(new Date(device.lastOnline),'dd/MM/yy HH:mm:ss')}</div>
        <div key={`${device.primaryId}_5`} className='data-grid-col'>{format(new Date(device.lastOffline),'dd/MM/yy HH:mm:ss')}</div>
        <div key={`${device.primaryId}_6`} className='data-grid-col'>{device.status}</div>
        <div key={`${device.primaryId}_7`} className='data-grid-col'>{format(new Date(device.lastUpdated),'dd/MM/yy HH:mm:ss')}</div>
        <div key={`${device.primaryId}_8`} className='data-grid-col icon-col'>{device.sendOfflineNotification ? <IoIosNotifications className='icon-status-active' onClick={() => handleNotify(device.primaryId,false)} /> : <IoMdNotificationsOff className='icon-status-inactive' onClick={() => handleNotify(device.primaryId,true)} />}</div>
        <div key={`${device.primaryId}_9`} className='data-grid-col icon-col'><MdEdit onClick={() => handleEdit(device.primaryId)} /></div>
        <div key={`${device.primaryId}_10`} className='data-grid-col icon-col'><AiOutlineAppstoreAdd onClick={() => handleAddApp(device.primaryId)} /></div>
        <div key={`${device.primaryId}_11`} className='data-grid-col icon-col'><BiDuplicate onClick={() => handleDuplicate(device.primaryId)} /></div>
        <div key={`${device.primaryId}_12`} className='data-grid-col icon-col'><BsClipboardCheck onClick={() => handleCopyId(device.id)} /></div>
        <div key={`${device.primaryId}_13`} className='data-grid-col icon-col'><MdDelete onClick={() => handleDelete(device.primaryId)} /></div>
    </div>
  )
}
export default DeviceGridRow