import React from 'react'
import { useStore } from './../../stores/store';
import DeviceGridRow from './DeviceGridRow';
import {BsSortUpAlt,BsSortDown} from 'react-icons/bs'
import { observer } from 'mobx-react-lite';
interface IProps {
  handleNotify: (id: number, notify: boolean) => void;
  handleEdit: (id: number) => void;
  handleAddApp: (id: number) => void;
  handleDuplicate: (id: number) => void;
  handleDelete: (id: number) => void;
}
    const DeviceGrid : React.FC<IProps>  = ({handleNotify,handleEdit, handleAddApp, handleDuplicate, handleDelete}) => {
    const {devicesStore:{devices,sortColumn,sortMode,setSort}} = useStore()
    const handleSetSort = (col: number) => {
        if(sortColumn === col)
            setSort(col,!sortMode);
        else
            setSort(col)
    }
  return (
     <>
        <div className='data-grid device-grid'>
            <div className='data-grid-row heading-row'>
                <div onClick={()=>handleSetSort(1)} className='data-grid-col'><span>Name</span>{sortColumn == 1 && (sortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div onClick={()=>handleSetSort(2)} className='data-grid-col'><span>Group</span>{sortColumn == 2 && (sortMode  ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div onClick={()=>handleSetSort(3)} className='data-grid-col'><span>App Id</span>{sortColumn == 3 && (sortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div onClick={()=>handleSetSort(4)} className='data-grid-col'><span>Last Online</span>{sortColumn == 4 && (sortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div onClick={()=>handleSetSort(5)} className='data-grid-col'><span>Last Offline</span>{sortColumn == 5 && (sortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div onClick={()=>handleSetSort(6)} className='data-grid-col'><span>Status</span>{sortColumn == 6 && (sortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div onClick={()=>handleSetSort(7)} className='data-grid-col'><span>Last Update</span>{sortColumn == 7 && (sortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div className='data-grid-col'>Notify</div>
                <div className='data-grid-col'>Edit</div>
                <div className='data-grid-col'>Add App</div>
                <div className='data-grid-col'>Duplicate</div>
                <div className='data-grid-col'>Copy Id</div>
                <div className='data-grid-col'>Delete</div>
            </div>
            
                {devices.length === 0 ? (
                    <div className='data-grid-no-records'>No records</div>
                ) : (
                    devices.map((device,i) => (
                        <DeviceGridRow key={`${device.id}_${i}`} device={device} handleNotify={handleNotify} handleEdit={handleEdit} handleAddApp={handleAddApp} handleDuplicate={handleDuplicate} handleDelete={handleDelete} />
                    ))
                )}
        </div>
    </>
  )
}



export default observer(DeviceGrid)