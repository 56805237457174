import { DeviceListEntity } from "../models/device";

export const truncate = (value?: string, max: number = 50) => {
    if(value){
        return (value.length > max) ? value.slice(0, max-1) + '\u2026' : value;
    }
    else
        return 'N/A';
}

export const exists = ( devices: DeviceListEntity[], id: number): boolean => {
    var found = false;
    for(var i = 0; i < devices.length; i++) {
    if (devices[i].primaryId === id) {
        found = true;
        break;
    }
    }
    return found;
}